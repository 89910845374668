import type {
  BlockDTO,
  CommentDTO,
  DiscussionDTO,
  PermissionDTO,
  PermissionGroupDTO,
  SegmentDTO,
  SpaceDTO,
  UserDTO,
} from '@next-space/fe-api-idl';
import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { isUndefined } from 'lodash-es';
import type { NextBlock, Share } from './type';
import { PAGE_TYPES, ROLE_WEIGHT } from './type';

export const isPageLike = (type?: BlockType) => {
  if (type === undefined) return false;
  return PAGE_TYPES.includes(type);
};

interface GetPermissionsParams {
  blocks: Record<string, BlockDTO | NextBlock>;
  discussions: Record<string, DiscussionDTO>;
  comments: Record<string, CommentDTO>;
  user?: UserDTO;
  userId?: string;
  permissionGroups?: PermissionGroupDTO[];
  isSharePage?: boolean;
  spaces: Record<string, SpaceDTO>;
  segmentsToText: (segments?: SegmentDTO[]) => string;
}

export const initShare: Share = {
  shared: false,
  illegal: false,
  isRestricted: false,
  allowDuplicate: true,
  allowSelectionCopy: true,
  allowDownload: true,
  allowShowSidebar: false,
  password: undefined,
  permissions: [],
  role: PermissionRole.NONE,
  roleWithoutPublic: PermissionRole.NONE,
  publicRole: PermissionRole.NONE,
  allowSeo: false,
  allowShowAIChat: false,
  allowSubscribe: false,
  allowShowBreadcrumb: true,
  linkValidDate: undefined,
  accessFee: 0,
  sharePageDistributionRatio: 0,
  accessFeeValidDays: 0,
  previewBlockCount: 0,
  originPrice: 0,
};

export const getPermissions = (state: GetPermissionsParams, uuid?: string) => {
  const {
    segmentsToText,
    blocks,
    discussions,
    comments,
    user,
    permissionGroups,
    isSharePage,
    spaces,
    userId: _userId,
  } = state;
  const data: Share = { ...initShare };

  const userId = _userId ?? user?.uuid;
  if (uuid == null) return data;

  let block = blocks[uuid];
  if (!block) {
    if (discussions[uuid] != null) {
      // 兼容 discussionId
      const blockId = discussions[uuid]?.parentId;
      const pageId = blockId == null ? undefined : lookupPageIdCommon(blockId, state);
      if (pageId != null) {
        block = blocks[pageId];
        uuid = pageId;
      }
    } else if (comments[uuid] != null) {
      // 兼容 commentId
      const discussionId = comments[uuid]?.parentId;
      const discussion = discussionId == null ? undefined : discussions[discussionId];
      const blockId = discussion?.parentId;
      const pageId = blockId == null ? undefined : lookupPageIdCommon(blockId, state);
      if (pageId != null) {
        block = blocks[pageId];
        uuid = pageId;
      }
    }
    if (block == null) {
      return data;
    }
  }
  if (!block.permissions) {
    return data;
  }

  if (block.permissions.length) {
    const IS_ILLEGAL = block.permissions.some((o) => o.type === PermissionType.ILLEGAL);
    data.illegal = data.illegal || IS_ILLEGAL;
  }

  const ids = new Set();
  const loop = (id: string) => {
    if (ids.has(id)) return;
    ids.add(id);

    const _block = blocks[id];
    // @ts-ignore SPACE 暂无权限
    if (!_block || _block.type === 'SPACE') return;
    if (_block.type === undefined) return;

    const permissions = _block.permissions.filter(
      (o) => o.type !== PermissionType.SPACE || spaces[_block.spaceId]?.permissions
    );

    if (permissions.length) {
      const getBiggerRole = (type: keyof PermissionDTO, value: any, role?: PermissionRole) => {
        const permission = data.permissions.find((p) => p[type] === value);
        if (
          permission &&
          role &&
          permission.role &&
          ROLE_WEIGHT[permission.role] > ROLE_WEIGHT[role]
        ) {
          return permission;
        }
      };

      const newPermissions = permissions
        .filter((o) => {
          return o.type !== PermissionType.ILLEGAL && o.type !== PermissionType.RESTRICTED;
        })
        .map((o) => {
          if (o.type === PermissionType.SPACE) {
            return getBiggerRole('type', o.type, o.role) || o;
          }
          if (o.type === PermissionType.GROUP) {
            return getBiggerRole('groupId', o.groupId, o.role) || o;
          }
          if (o.type === PermissionType.USER) {
            return getBiggerRole('userId', o.userId, o.role) || o;
          }
          if (o.type === PermissionType.PUBLIC) {
            return getBiggerRole('type', o.type, o.role) || o;
          }
          return o;
        });

      const diffPermissions = data.permissions.filter((o) => {
        if (o.type === PermissionType.ILLEGAL || o.type === PermissionType.RESTRICTED) {
          return false;
        }
        if (o.type === PermissionType.SPACE || o.type === PermissionType.PUBLIC) {
          return newPermissions.every((p) => p.type !== o.type);
        }
        if (o.type === PermissionType.GROUP) {
          return newPermissions.every((p) => p.groupId !== o.groupId);
        }
        return newPermissions.every((p) => p.userId !== o.userId);
      });

      data.permissions = [...newPermissions, ...diffPermissions];

      // 可以受限+分享
      const publicPermission = permissions.find((o) => o.type === PermissionType.PUBLIC);
      const IS_RESTRICTED = permissions.some((o) => o.type === PermissionType.RESTRICTED);
      //需要在这里设置，不继承
      if (
        publicPermission &&
        publicPermission.openPreview !== undefined &&
        publicPermission.openPreview !== null &&
        id === uuid
      ) {
        data.openPreview = publicPermission.openPreview;
      }

      // 子页面先开分享 然后父页面开分享 子页面的【允许拷贝权限】【侧边栏显示权限】,会被父页面覆盖
      if (publicPermission && data.shared) {
        if (publicPermission.allowDuplicate || isUndefined(publicPermission.allowDuplicate)) {
          data.allowDuplicate = true;
        }

        if (
          publicPermission.allowSelectionCopy ||
          isUndefined(publicPermission.allowSelectionCopy)
        ) {
          data.allowSelectionCopy = true;
        }

        if (publicPermission.allowDownload || isUndefined(publicPermission.allowDownload)) {
          data.allowDownload = true;
        }
        if (publicPermission.shareShowSidebar) {
          data.allowShowSidebar = true;
        }
        if (publicPermission.allowShowBreadcrumb) {
          data.allowShowBreadcrumb = true;
        }
        if (publicPermission.allowSeo) {
          data.allowSeo = true;
        }
        if (publicPermission.allowSubscribe) {
          data.allowSubscribe = true;
        }
        if (publicPermission.allowShowAIChat) {
          data.allowShowAIChat = true;
        }
        if (publicPermission.accessFee) {
          data.accessFee = publicPermission.accessFee;
        }
        if (publicPermission.originPrice) {
          data.originPrice = publicPermission.originPrice;
        }
        if (publicPermission.sharePageDistributionRatio) {
          data.sharePageDistributionRatio = publicPermission.sharePageDistributionRatio;
        }
        if (publicPermission.accessFeeValidDays) {
          data.accessFeeValidDays = publicPermission.accessFeeValidDays;
        }
        if (publicPermission.previewBlockCount) {
          data.previewBlockCount = publicPermission.previewBlockCount;
        }
        data.linkValidDate = publicPermission.linkValidDate;
      }

      // 父级有密码，但子没有密码。继承父的
      if (publicPermission?.password && !data.password) {
        data.password = publicPermission.password;
      }

      // 分享只查到最近的一个具有 public 的父块
      if (publicPermission && !data.shared) {
        data.shared = !!publicPermission;

        data.allowDuplicate = publicPermission.allowDuplicate ?? true;
        data.allowSelectionCopy = publicPermission.allowSelectionCopy ?? true;
        data.allowDownload = publicPermission.allowDownload ?? true;
        data.allowShowBreadcrumb = publicPermission.allowShowBreadcrumb ?? true;
        data.allowShowSidebar = publicPermission.shareShowSidebar ?? false;
        data.password = publicPermission.password;
        data.allowSeo = publicPermission.allowSeo ?? false;
        data.allowSubscribe = publicPermission.allowSubscribe ?? true;
        data.allowShowAIChat = publicPermission.allowShowAIChat ?? false;
        data.linkValidDate = publicPermission.linkValidDate;
        data.accessFee = publicPermission.accessFee;
        data.originPrice = publicPermission.originPrice;
        data.sharePageDistributionRatio = publicPermission.sharePageDistributionRatio;
        data.accessFeeValidDays = publicPermission.accessFeeValidDays;
        data.previewBlockCount = publicPermission.previewBlockCount;

        if (uuid !== id) {
          data.parentId = _block.uuid;
          data.title = segmentsToText(_block.data.segments);
        }
      }

      if (IS_RESTRICTED) {
        if (uuid === id) {
          data.isRestricted = true;
        }
        if (_block.parentId !== _block.spaceId) {
          let parent = blocks[_block.parentId];

          const pIds = new Set();

          while (parent && !isPageLike(parent.type)) {
            const pId = parent.parentId;
            if (pIds.has(pId)) break;
            pIds.add(pId);
            parent = blocks[parent.parentId];
          }

          if (!parent) return;

          data.parentId = parent.uuid;
          data.title = segmentsToText(parent.data.segments);
        }

        // 受限直接退出递归
        return;
      }
    }

    loop(_block.parentId);
  };

  loop(uuid);

  const ownPermission = data.permissions.find((p) => p.userId === userId);
  const publicPermission = data.permissions.find((p) => p.type === PermissionType.PUBLIC);

  const groupPermissions = data.permissions.filter((p) => {
    const group = permissionGroups?.find((g) => g.id === p.groupId);
    return group?.userIds.includes(userId ?? '');
  });

  const spacePermission = data.permissions.find((p) => p.type === PermissionType.SPACE);

  if (!spacePermission) {
    data.permissions.unshift({ type: PermissionType.SPACE, role: PermissionRole.NONE });
  }

  const allPermissions = [ownPermission, ...groupPermissions];

  if (!ownPermission?.isGuest) {
    allPermissions.push(spacePermission);
  }

  // 取 space/group/自己 最大权限
  data.roleWithoutPublic = allPermissions.reduce(
    (pre: PermissionRole, permission: PermissionDTO | undefined) => {
      if (!permission) return pre;
      const role = permission.role ?? PermissionRole.NONE;
      return ROLE_WEIGHT[role] > ROLE_WEIGHT[pre] ? role : pre;
    },
    PermissionRole.NONE
  );

  // 开启了分享的页面认为 至少只读权限
  const roleWithPublic =
    data.shared && data.roleWithoutPublic === PermissionRole.NONE
      ? PermissionRole.READER
      : data.roleWithoutPublic;
  // 分享页目前可以评论
  data.role = isSharePage ? publicPermission?.role ?? PermissionRole.READER : roleWithPublic;
  data.publicRole = publicPermission?.role ?? PermissionRole.NONE;
  return data;
};

export const setPermission = (
  uuid: string,
  item: PermissionDTO,
  options: {
    removeBlockPermission: (uuid: string, permission: PermissionDTO) => void;
    setBlockPermission: (uuid: string, permission: PermissionDTO) => void;
  }
) => {
  const { removeBlockPermission, setBlockPermission } = options;
  const permission: PermissionDTO = {
    role: item.role,
    type: item.type,
  };

  if (item.type === PermissionType.GROUP) {
    permission.groupId = item.groupId;
  }

  if (item.type === PermissionType.USER) {
    if (item.isGuest) {
      permission.isGuest = item.isGuest;
    }
    permission.userId = item.userId;
  }

  if (item.role === PermissionRole.NONE) {
    removeBlockPermission(uuid, permission);
  } else {
    setBlockPermission(uuid, permission);
  }
};

// 向上找到所属页面
export const lookupPageIdCommon = (
  blockId: string,
  state: GetPermissionsParams
): string | undefined => {
  const { blocks, comments, discussions } = state;
  const block = blocks[blockId];

  if (!block) {
    const comment = comments[blockId];
    if (comment) {
      return lookupPageIdCommon(comment.parentId, state);
    }

    const discussion = discussions[blockId];
    if (discussion) {
      return lookupPageIdCommon(discussion.parentId, state);
    }
    return;
  }

  if (isPageLike(block.type)) {
    return block.uuid;
  }

  if (block.parentId === block.spaceId) return block.uuid;
  return lookupPageIdCommon(block.parentId, state);
};

export const getMentionBlockShowInfo = (type?: BlockType) => {
  if (!type) {
    return { showPageIcon: true, showPage: true };
  }
  const showPageIcon = ![BlockType.TEXTAREA, BlockType.HEADER].includes(type);
  const showPage = [
    BlockType.BOOKMARK,
    BlockType.EMBED,
    BlockType.DIVIDER,
    BlockType.TABLE,
    BlockType.TEMPLATE,
    BlockType.SYNC_CONTAINER,
    BlockType.SYNC_REFERENCE,
  ].includes(type);
  return { showPageIcon, showPage };
};
