import { Fragment, useState, type FC } from 'react';
import { cx } from '../../cx';
import { Icon } from '../../icon-svg';
import { PayType } from '../../utils';
import { Button } from '../button';
import { PayItems } from './const';

const _payList = [PayType.stripe, PayType.yoomoneyRu, PayType.sbpRu, PayType.cardRu];
interface Props {
  className?: string;
  onPayClick?: (payType: PayType) => void;
  showRuPay?: boolean;
}
export const BuildinSubscribePay: FC<Props> = (props) => {
  const [active, setActive] = useState<PayType>(PayType.stripe);
  return (
    <div className={cx('p-6', props.className)}>
      <PayMethodList active={active} showRuPay={props.showRuPay} setActive={setActive} />
      <Button
        hidden={!props.onPayClick}
        className="mt-10 w-full h-10"
        colorType="active"
        onClick={() => {
          props.onPayClick?.(active);
        }}
      >
        立即支付
      </Button>
    </div>
  );
};

export const PayMethodList: FC<{
  active: PayType;
  setActive: (active: PayType) => void;
  showRuPay?: boolean;
  payList?: PayType[];
  className?: string;
}> = (props) => {
  const { className, active, setActive, payList = _payList, showRuPay } = props;
  return (
    <div className="space-y-4 text-t2">
      {payList.map((payType, index) => {
        // @ts-ignore 没有未知支付方式
        const item = PayItems[payType];
        const curActive = active === payType;

        // 如果不是俄罗斯系统
        if (!showRuPay) {
          if ([PayType.sbpRu, PayType.yoomoneyRu, PayType.cardRu, PayType].includes(payType)) {
            return <Fragment key={payType}></Fragment>;
          }
        }

        return (
          <Fragment key={index}>
            {index === 1 && showRuPay && (
              <div className="text-grey3">其他付款方式（仅支持俄罗斯卡）</div>
            )}
            <div
              className={cx(
                className,
                'border-2 border-grey6 flex items-center space-x-2 rounded-md bg-white3 p-2 animate-click',
                curActive && 'border-active_color bg-active_color_10'
              )}
              onClick={() => {
                setActive(payType);
              }}
            >
              <Icon
                size="middle"
                className={curActive ? 'text-active_color' : 'text-grey3'}
                name={curActive ? 'IcCircleCheck' : 'IcCircleCheckbox'}
              />
              <span>{item.title}</span>
              {item.img && <img className="h-4 !ml-auto" src={item.img} alt={item.title} />}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
