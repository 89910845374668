import type { IconData } from '@next-space/fe-api-idl';
import type { CSSProperties, FC } from 'react';
import { loginInjector } from '../../utils/init';

interface AvatarType {
  name?: string;
  className?: string;
  style?: CSSProperties;
  color?: string;
  icon?: IconData;
  onClick?: (e: React.MouseEvent) => void;
  iconSize?: number;
}
/**
 * 头像
 */
export const Avatar: FC<AvatarType> = (props) => {
  const { name = '', color = '#828282', icon } = props;
  const value = getAvatarText(name);
  const isUpload = icon?.type === 'upload' && icon.value;
  const isEmoji = (icon?.type === 'emoji' && icon.value) || (value && value.length > 1);
  return (
    <span
      className="text-h4 flex h-[50px] w-[50px] flex-shrink-0 select-none items-center justify-center rounded-full uppercase leading-none text-white2"
      style={{
        ...props.style,
        backgroundColor: isEmoji || isUpload || !color ? '' : color,
      }}
      onClick={props.onClick}
    >
      {isUpload ? (
        <></>
      ) : isEmoji ? (
        <loginInjector.EmojiIcon value={icon?.value} size={props.iconSize} />
      ) : (
        value
      )}
      {isUpload && (
        <img
          src={icon.value?.startsWith('http') ? icon.value : getImageCdnUrl(icon.value ?? '')}
          className="h-full w-full rounded-full object-cover"
        />
      )}
    </span>
  );
};

/** 获取名字首位字 */
const getAvatarText = (name: string) => {
  return name.split('')[0];
};

const compressImageSupport = /^(jpg|jpeg|png|bmp|webp|tiff)$/i;

function getImageCdnUrl(ossName?: string) {
  if (!ossName) return '';
  /**
   * 由于oss压缩图片只支持特定几种格式，所以这里做个简单的判断
   */
  const index = ossName.lastIndexOf('.');
  const extName = ossName.substring(index + 1);
  let imgProcess = '';
  if (compressImageSupport.test(extName.toLocaleLowerCase())) {
    imgProcess = `x-oss-process=image/resize,w_${
      500 * Math.ceil(window.devicePixelRatio)
    }/quality,q_80/`;
  }
  return `${loginInjector.cdnHost}${encodeURIComponent(ossName)}?${imgProcess}`;
}
