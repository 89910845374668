import type { MouseEventHandler } from 'react';

export interface ComponentProp {
  // key?: number|string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick?: MouseEventHandler;
}
export interface ControlComponentProp extends ComponentProp {
  value?: string | boolean | number;
  onChange?: Function | any;
}

export const IconSizeStyle = {
  normal: 'w-[1em] h-[1em]',
  /** 8px */
  tiny: 'w-2 h-2',
  /** 9px */
  xxxsmall: 'w-[9px] h-[9px]',
  /** 10px */
  xxxsmall_10: 'w-2.5 h-2.5',
  /** 12px */
  xxsmall: 'w-3 h-3',
  /** 14px */
  xsmall: 'w-3.5 h-3.5',
  /** 16px */
  small: 'w-4 h-4',
  /** 20px */
  middle: 'w-5 h-5',
  /** 24px */
  large: 'w-6 h-6',
  /** 26px */
  xlarge: 'w-[26px] h-[26px]',
  /** 28px */
  xxlarge: 'w-7 h-7',
  xxlarge_9: 'w-9 h-9',
  /** 40px */
  xxxlarge: 'w-10 h-10',
  /**46px */
  midMax: 'w-[46px] h-[46px]',
  /** 50px */
  max: 'w-[50px] h-[50px]',
  /** 80px */
  xmax: 'w-20 h-20',
  /** 110px */
  preview: 'w-[110px] h-[110px]',
  auto: 'w-full h-full',
};

export type IconSize = keyof typeof IconSizeStyle;
