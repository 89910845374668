import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Images } from '../../images';
import { CountDown } from './count-down';

interface CaptchaCompType {
  onSendVerifyCode: () => Promise<boolean | undefined>;
  //默认样式是否已经开始倒计时
  defaultStartCount?: boolean;
  autoClick?: boolean;
  showIcon?: boolean;
  className?: string;
  disable?: boolean;
  countDownPrefix?: string;
  sendText?: string;
  resendText?: string;
}

/**
 * 验证码倒计时组件
 */
export const CaptchaComp: FC<CaptchaCompType> = ({
  onSendVerifyCode,
  autoClick,
  showIcon = true,
  className,
  disable,
  sendText,
  resendText,
  countDownPrefix,
}) => {
  const [startCount, setStartCount] = useState(false);
  const [buttonText, setButtonText] = useState(sendText ?? '获取验证码');
  const ref = useRef<HTMLButtonElement>(null);

  const onFinish = useCallback(() => {
    setStartCount(false);
  }, []);

  useEffect(() => {
    if (autoClick) {
      ref.current?.click();
    }
  }, [autoClick]);

  return (
    <button
      ref={ref}
      disabled={disable}
      className={cx(
        'text-t4 mr-1 flex h-full w-full items-center font-normal text-black h-lg:text-t2',
        className,
        { 'opacity-50 cursor-not-allowed': disable }
      )}
      type="button"
      onClick={async () => {
        if (startCount) return;
        const success = await onSendVerifyCode();
        if (!success) return;
        setStartCount(true);
        setButtonText(resendText ?? '重新获取验证码');
      }}
    >
      {startCount ? (
        <CountDown
          time={60}
          className="text-grey3"
          prefix={countDownPrefix ?? '倒计时'}
          onFinish={onFinish}
        />
      ) : (
        <>
          {buttonText}
          {showIcon && (
            <img
              src={Images.IcLoginCode}
              className="ml-1 h-3 w-3 dark:invert dark:opacity-50" // animate__animated animate__headShake
            />
          )}
        </>
      )}
    </button>
  );
};
