export const percentToNumber = (percent: string) => {
  const num = percent.split('%')[0] ?? '';
  if (!num) return 0;

  const scale = Math.pow(10, (num.split('.')[1] ?? '').length);
  return (Number(num) * scale) / (100 * scale);
};

export const maxNumber = (num1 = 0, num2 = 0) => {
  if (num1 === -1) {
    num1 = Infinity;
  }
  if (num2 === -1) {
    num1 = Infinity;
  }
  return Math.max(num1, num2);
};
