import { isBuildIn } from '../build-in';

export const getCurrencySymbols = (type?: number | string): string => {
  if (type === undefined && isBuildIn()) {
    type = 2;
  }
  switch (type) {
    case 0:
      return '积分';
    case 1:
      return '¥';
    case 2:
      return '$';
    case 3:
      return '€';
    default:
      return '¥';
  }
};
