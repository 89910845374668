import { commonPublicLink } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { TeamInviteStatus } from '../../hook/use-team-invite';
import { Images } from '../../images';
import { Button } from '../../uikit/web/button';
import { loginInjector } from '../../utils/init';

/** 页面未找到 */
export const JoinSpaceError: FC<{
  title?: string;
  children?: ReactNode;
  btnText?: string;
  onClick: () => void;
  errorType?: TeamInviteStatus;
  joinType?: 'guest' | 'space';
  className?: string;
  errorMsg?: string;
}> = ({ className, errorType, btnText, onClick, joinType = 'space', errorMsg }) => {
  const title = useMemo(() => {
    if (errorMsg) return errorMsg;
    if (errorType === TeamInviteStatus.LINK_ERROR) {
      return '邀请链接已失效';
    }
    if (errorType === TeamInviteStatus.MEMBER_FULL) {
      if (joinType === 'guest') {
        return `外部协作者席位不足，请联系空间管理员升级空间获取更多席位`;
      }
      return `空间席位不足，请联系空间管理员升级空间获取更多席位`;
    }
    return '加入失败';
  }, [errorMsg, errorType, joinType]);

  useEffect(() => {
    loginInjector.setBrowserTitle(title, { type: 'emoji', value: '🌚' });
  }, [title]);

  return (
    <div
      className={cx(
        'cursor-default pt-[8vh] flex flex-col items-center justify-between w-full flex-1 relative',
        className
      )}
    >
      <div className="flex items-center justify-center flex-col space-y-5">
        <img src={Images.partnership} className="dark:invert" alt="" />
        <p className="text-grey3 text-t2-bold whitespace-pre-wrap text-center">{title}</p>
        <Button colorType="primary" className="h-12 w-full" onClick={onClick}>
          {btnText}
        </Button>
      </div>
      <div className="text-t4 py-6">
        访问
        <a
          className="underline-offset-2 underline mx-1 animate-click"
          href={commonPublicLink.helpCenter}
          target="_blank"
          rel="noreferrer"
        >
          帮助中心
        </a>
        ，了解更多信息
      </div>
    </div>
  );
};
