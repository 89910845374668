import { isBuildIn } from './build-in';

const dsR1Model = {
  title: 'DeepSeek R1',
  value: 'ds-r1',
};
const dsV3Model = {
  title: 'DeepSeek V3',
  value: 'ds-v3',
};

// const dbPro32kModel = {
//   title: '豆包 Pro 32k',
//   value: 'db-pro-32k',
// };

const db15Pro256kModel = {
  title: '豆包 Pro 256k',
  value: 'db-1-5-pro-256k',
};

const gpt4oMiniModel = {
  title: 'GPT-4o Mini',
  value: 'gpt-4o-mini',
};

export const AIModeList = isBuildIn()
  ? [dsR1Model, dsV3Model, gpt4oMiniModel]
  : [dsR1Model, dsV3Model, db15Pro256kModel];

export const DefaultAIModel = dsR1Model;

export const ThinkModel = [dsR1Model.value];

export const findAIModel = (value: string) => {
  return AIModeList.find((model) => model.value === value) || DefaultAIModel;
};
