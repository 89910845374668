import type { UserDTO } from '@next-space/fe-api-idl';
import classnames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { VITE_PRODUCT_NAME } from '../../const';
import { TeamInviteStatus, useTeamInvite } from '../../hook/use-team-invite';
import { Images } from '../../images';
import { ViewType } from '../../types';
import { Avatar } from '../../uikit/web/avatar';
import { Button } from '../../uikit/web/button';
import { encryptionPhone } from '../../utils';
import { loginInjector } from '../../utils/init';
import { JoinSpaceError } from '../common/join-space-error';
import { Login } from '../login';

interface Props {
  onSuccess: (spaceId: string) => void;
  goBackHome: () => void;
  onLogin?: (user: UserDTO) => void;
  setShowFooter?: (status: boolean) => void;

  teamInviteCode: string;
  inviteCode?: string;
  promotionChannel?: string;
  styleType: 'default' | 'lite' | 'build-in-pc' | 'build-in-lite';
  hideThirdPartLogin?: boolean;
}
/** 加入空间*/
export const TeamInvite: FC<Props> = (props) => {
  const { setShowFooter, teamInviteCode } = props;
  const { status, user, teamSpaceInfo, joinTeamSpace, setUser, error } =
    useTeamInvite(teamInviteCode);
  const [showLogin, setShowLogin] = useState(false);
  const [openWechat, setOpenWechat] = useState(false);

  useEffect(() => {
    if (status === TeamInviteStatus.JOINING) {
      loginInjector.message.success({
        content: '正在加入空间',
        duration: 3000,
      });
    } else if (status === TeamInviteStatus.ALREADY_IN && teamSpaceInfo) {
      loginInjector.message.success({
        content: '您已是此空间成员，正在跳转至团队空间',
        duration: 3000,
      });
      setTimeout(() => {
        props.onSuccess(teamSpaceInfo.space.uuid);
      }, 3000);
    }

    if (status && [TeamInviteStatus.LINK_ERROR, TeamInviteStatus.MEMBER_FULL].includes(status)) {
      setShowFooter?.(false);
    }
  }, [props, setShowFooter, status, teamSpaceInfo]);

  useEffect(() => {}, []);

  if (status && [TeamInviteStatus.LINK_ERROR, TeamInviteStatus.MEMBER_FULL].includes(status)) {
    return (
      <JoinSpaceError
        btnText={user ? `返回 ${VITE_PRODUCT_NAME}` : '登录'}
        errorType={status}
        errorMsg={error}
        onClick={() => {
          if (user) {
            props.goBackHome();
          } else {
            window.location.href = `/login`;
            setShowLogin(showLogin);
          }
        }}
      />
    );
  }
  if (!teamSpaceInfo) return null;

  if (openWechat) {
    return (
      <Login
        promotionChannel={props.promotionChannel}
        defaultViewState={{
          type: ViewType.WECHAT_LOGIN,
        }}
        complete={false}
        quickRegister={true}
        inviteCode={props.inviteCode}
        styleType={props.styleType ?? 'default'}
        onStep={(viewType) => {
          if (viewType === ViewType.LOGIN) {
            //点了关闭按钮后会回到登录页，这里监听并关掉
            setOpenWechat(false);
          }
        }}
        onRegister={(user) => {
          props.onLogin?.(user);
        }}
        onSuccess={async (user) => {
          const success = await joinTeamSpace(user);
          if (success) {
            props.onLogin?.(user);
            props.onSuccess(teamSpaceInfo.space.uuid);
          }
        }}
      />
    );
  }
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center sm:px5 w-[400px] pt-[10vh] h-md:pt-[8vh]">
        <Avatar
          className="w-[70px] h-[70px] mx-auto text-[60px]"
          color={teamSpaceInfo.space.backgroundColor}
          name={teamSpaceInfo.space.title}
          icon={teamSpaceInfo.space.icon}
          iconSize={60}
        />
        <div className="text-ellipsis mt-5 text-center">
          邀请你加入团队
          <br />
          {teamSpaceInfo.space.title}
        </div>
        {user && !showLogin ? (
          <>
            <Button
              colorType="primary"
              className={classnames(
                'w-full h-[50px] text-t1-medium h-md:text-h4 !opacity-100 mt-7'
              )}
              size="auto"
              onClick={async () => {
                const success = await joinTeamSpace(user);
                if (success) {
                  props.onSuccess(teamSpaceInfo.space.uuid);
                }
              }}
            >
              加入团队
            </Button>
            <div className="text-grey3 text-t4 text-center mt-3">
              当前账号：{user.nickname}（{encryptionPhone(user.phone || user.email)}）
              <span
                className="text-black cursor-pointer text-t4-medium border-b border-black"
                onClick={() => setShowLogin(true)}
              >
                切换
              </span>
            </div>
          </>
        ) : (
          <Login
            inviteCode={props.inviteCode}
            promotionChannel={props.promotionChannel}
            quickRegister={true}
            styleType={props.styleType ?? 'default'}
            onRegister={(user) => {
              props.onLogin?.(user);
            }}
            onSuccess={async (user) => {
              setUser(user);
              props.onLogin?.(user);
              const success = await joinTeamSpace(user);
              if (success) {
                props.onSuccess(teamSpaceInfo.space.uuid);
              }
            }}
          />
        )}
        {props.styleType !== 'build-in-pc' && !props.hideThirdPartLogin && (
          <>
            <div className="mt-10 h-px w-full bg-grey6 my-7 sm:my-3" />
            <div className="flex justify-center">
              <img
                src={Images.wechatBtn}
                className="cursor-pointer mx-3.5 hover:opacity-80"
                onClick={() => {
                  setOpenWechat(true);
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
