import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import type { Layout, Stripe } from '@stripe/stripe-js';
import type { FC, FormEvent } from 'react';
import colors from '@flowus/tailwind-config/colors.json';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../button';
import { useIsDarkMode } from '../../utils';
import { useOpenModal } from '../../next-modal';
import type { PreOrderDTO } from '@next-space/fe-api-idl';
import { getIsApp } from '../../utils/get-is-app';
import { message } from '../message';
import { Modals } from '@flowus/shared';
import { createModel, useModel } from '../../create-model';

let VITE_STRIPE_PUBLIC_KEY = '';
export const setStripePublicKey = (key: string) => {
  VITE_STRIPE_PUBLIC_KEY = key;
};
// #region types
interface StripeFormProps {
  submit: () => void;
  className?: string;
}
interface StripeFormContextProps {
  clientSecret: string;
}
// #endregion

// #region checkout
const useStripeCheckOut = (props: { submit: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const openModal = useOpenModal();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements || isLoading) {
      return;
    }

    openModal.loading({ modalId: Modals.STRIPE_CHECKOUT, autoClose: false });
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: location.origin,
      },
      redirect: 'if_required',
    });

    // closeAllModal();
    if (!error) {
      props.submit();
    } else {
      message.error(error.message ?? 'An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return { onSubmit, email, isLoading, setEmail };
};

const StripeCheckOutContext = createModel(useStripeCheckOut);
export const useStripeCheckOutContext = () => useModel(StripeCheckOutContext);
export const StripeCheckoutForm: FC = () => {
  const { setEmail } = useStripeCheckOutContext();
  return (
    <>
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.value.email)}
      />
      <PaymentElement id="payment-element" options={{ layout: 'tabs' as Layout }} />
    </>
  );
};
// #endregion

// #region form
const useStripeForm = (props: StripeFormContextProps) => {
  const { clientSecret } = props;
  const isDarkModel = useIsDarkMode();

  const appearance = {
    variables: isDarkModel
      ? {
          colorPrimary: colors.active_color,
          colorBackground: colors.dark.white2,
          colorText: colors.dark.white,
        }
      : undefined,
  };

  const options = {
    clientSecret,
    appearance,
    locale: 'en',
  };

  return { options, clientSecret };
};

const StripeFormContext = createModel(useStripeForm);
const useStripeFormContext = () => useModel(StripeFormContext);

export const StripeForm: FC<StripeFormProps & StripeFormContextProps> = (props) => {
  const { clientSecret, submit, children, className } = props;
  return (
    <StripeFormContext clientSecret={clientSecret}>
      <StripeFormContent className={className} submit={submit}>
        {children}
      </StripeFormContent>
    </StripeFormContext>
  );
};

const StripeFormContent: FC<StripeFormProps> = (props) => {
  const { options } = useStripeFormContext();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    void (async () => {
      const { loadStripe } = await import('@stripe/stripe-js');
      if (!VITE_STRIPE_PUBLIC_KEY) {
        console.error('VITE_STRIPE_PUBLIC_KEY is undefined');
      }
      setStripePromise(loadStripe(VITE_STRIPE_PUBLIC_KEY));
    })();
  }, []);

  return (
    <>
      {options.clientSecret && (
        <Elements key={options.clientSecret} options={options as any} stripe={stripePromise}>
          <StripeCheckOutContext submit={props.submit}>
            <StripeFormSubmit className="p-5 space-y-5">
              <StripeCheckoutForm />
              <Button colorType="active">Pay</Button>
            </StripeFormSubmit>
          </StripeCheckOutContext>
        </Elements>
      )}
    </>
  );
};

const StripeFormSubmit: FC<{ className?: string }> = (props) => {
  const { className, children } = props;
  const { onSubmit } = useStripeCheckOutContext();

  return (
    <form
      id="payment-form"
      // className={cx('animate__animated animate__fadeIn', className)}
      className={className}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};
// #endregion

const useOpenStripe = (success?: () => void) => {
  const openModal = useOpenModal();
  const successRefCb = useRef<Function>();
  successRefCb.current = success;

  return useCallback(
    (opt: { clientSecret?: string }) => {
      if (!opt.clientSecret) {
        return;
      }

      openModal.modal({
        content: (_) => (
          <StripeForm
            submit={() => {
              _.onCloseModal();
              successRefCb.current?.();
              if (getIsApp()) {
                setTimeout(() => {
                  window.close();
                }, 5000);
              }
            }}
            clientSecret={`${opt.clientSecret}`}
          />
        ),
      });
    },
    [openModal]
  );
};

export const useOpenIframePay = () => {
  const openModal = useOpenModal();
  return useCallback(
    (params: { url?: string }) => {
      if (!params.url) {
        return;
      }
      window.open(params.url);
      openModal.warning({
        title: '已完成支付？',
        colorType: 'active',
        cancelText: '取消',
        confirmText: '已支付',
        confirm() {
          location.reload();
        },
      });
    },
    [openModal]
  );
};

export const useOpenBuildInPay = (successCb?: () => void) => {
  const openStripe = useOpenStripe(successCb);
  const openIframePay = useOpenIframePay();

  return useCallback(
    (order: PreOrderDTO) => {
      if (order.redirectUrl) {
        openIframePay({ url: order.redirectUrl });
      } else {
        openStripe({ clientSecret: order.clientSecret });
      }
    },
    [openIframePay, openStripe]
  );
};
