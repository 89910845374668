import katex from 'katex';
import 'katex/contrib/mhchem';
import type { FC } from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import './latex.css';
import { loadCSSWithXHR } from '../utils';
import { isBuildIn } from '../build-in';
import { cx } from '../cx';

export const latexRender = (value: string, element?: HTMLElement | null) => {
  let errorMsg = '';
  if (!element) {
    try {
      katex.renderToString(value, {
        output: 'html',
        displayMode: true,
        throwOnError: true,
      });
    } catch (e: any) {
      errorMsg = `${e.message}`.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    return { errorMsg };
  }
  try {
    katex.render(value, element, {
      output: 'html',
      displayMode: true,
      throwOnError: true,
      strict: false,
    });
  } catch (e: any) {
    errorMsg = e?.message
      ? `${e.message}`.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      : '公式错误';
  }

  return {
    errorMsg,
  };
};
const flowusCssUrl = 'https://lib.baomitu.com/KaTeX/0.16.9/katex.min.css';
const flowusCssUrl2 = 'https://cdn2.flowus.cn/emoji/katex.min.css';

const buildIncssUrl = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css';
const buildIncssUrl2 = 'https://cdn.buildin.ai/emoji/katex.min.css';
const cssUrls = isBuildIn() ? [buildIncssUrl, buildIncssUrl2] : [flowusCssUrl, flowusCssUrl2];

export const LatexComponent: FC<{
  className?: string;
  inline?: boolean;
  text?: string;
  onClick?: () => void;
  onRender?: (error: string) => void;
  children?: React.ReactNode;
}> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { className, text = '', onClick, onRender, inline } = props;
  // useEffect(() => {
  //   const loadCss = async () => {
  //     for (const cssUrl of cssUrls) {
  //       try {
  //         //如果加载成功就break,不成功就catch住继续加载下一个
  //         await loadCSSWithXHR(cssUrl, 10000);
  //         break;
  //       } catch (err) {
  //         //do nothing
  //       }
  //     }
  //   };
  //   void loadCss();
  // }, []);

  useLayoutEffect(() => {
    const { errorMsg } = latexRender(text, divRef.current);
    onRender?.(errorMsg);
  }, [onRender, text]);

  return (
    <span className={cx(className, 'latex-workround')} onClick={onClick}>
      {inline ? <span ref={divRef} className="inline-latex inline-block" /> : <div ref={divRef} />}
      {props.children}
    </span>
  );
};
