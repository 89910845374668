import type { UserDTO } from '@next-space/fe-api-idl';
import { useEffect, useState } from 'react';

import { loginInjector } from '../utils/init';

export enum TeamInviteStatus {
  ALREADY_IN = 0,
  MEMBER_FULL = 1,
  LINK_ERROR = 2,
  JOINING = 3,
  JOIN_SUCCESS = 4,
}
export const useTeamInvite = (inviteCode: string) => {
  const [status, setStatus] = useState<TeamInviteStatus>();
  const [user, setUser] = useState<UserDTO>();
  const [error, setError] = useState('');
  const [teamSpaceInfo, setTeamSpaceInfo] =
    useState<Awaited<ReturnType<typeof loginInjector.request.infra.getInvitationInfo>>>();
  useEffect(() => {
    void loginInjector.request.infra.getMyUserInfo.raw().then((res) => {
      if (res.code === 200) {
        setError('');
        setUser(res.data);
      } else {
        setError(res.msg);
      }
    });
    void loginInjector.request.infra.getInvitationInfo.raw(inviteCode).then((res) => {
      if (res.code === 200) {
        setTeamSpaceInfo(res.data);
        if (res.data.joined) {
          setStatus(TeamInviteStatus.ALREADY_IN);
        }
      } else {
        setError(res.msg);
      }
    });
  }, [inviteCode]);

  const joinTeamSpace = async (user: UserDTO) => {
    setStatus(TeamInviteStatus.JOINING);
    const res = await loginInjector.request.infra.getUserRoot(user.uuid);
    if (res.spaces?.[teamSpaceInfo?.space.uuid ?? '']) {
      setStatus(TeamInviteStatus.ALREADY_IN);
      return;
    }
    await loginInjector.request.infra.joinSpaceByInvitation(inviteCode);
    setStatus(TeamInviteStatus.JOIN_SUCCESS);
    return true;
  };
  return {
    status,
    teamSpaceInfo,
    user,
    setUser,
    joinTeamSpace,
    error,
  };
};
