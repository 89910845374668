import { GlobalImages } from '../../images';
import { PayType } from '../../utils';

export const PayItems = {
  [PayType.sberPayRu]: {
    title: 'SberPay',
    img: GlobalImages.paySber,
  },
  [PayType.cardRu]: {
    title: 'Visa/Mastercard/Mir',
    img: GlobalImages.payCard,
  },
  [PayType.sbpRu]: {
    title: 'SBP',
    img: GlobalImages.paySbp,
  },
  [PayType.stripe]: {
    title: 'Stripe',
    img: GlobalImages.payStripe,
  },
  [PayType.yoomoneyRu]: {
    title: 'Yoomoney',
    img: GlobalImages.payYoomoney,
  },
  [PayType.alipay]: {
    title: '支付宝',
    img: GlobalImages.aliPay,
  },
  [PayType.wxpay]: {
    title: '微信支付',
    img: GlobalImages.weChatPay,
  },
  [PayType.offline]: {
    title: '线下支付',
    img: '',
  },
};
