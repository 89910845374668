import type { SegmentDTO } from '@next-space/fe-api-idl';
import { DateFormat, TextType, TimeFormat } from '@next-space/fe-api-idl';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { isBuildIn } from '../build-in';

dayjs.extend(customParseFormat); //支持自定义格式比如：dayjs('05/02/69 1:02:03 PM -05:00', 'MM/DD/YY H:mm:ss A Z')
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);

const weekDesc = new Map<number, string>([
  [0, '日'],
  [1, '一'],
  [2, '二'],
  [3, '三'],
  [4, '四'],
  [5, '五'],
  [6, '六'],
]);

const comparisonTemplate = 'YYYY-MM-DD';
export const COMPLETE_DATE_FORMAT = 'YYYY年M月D日';
/**
 * 获取相对描述:比如今天/明天/后天/昨天/前天/上周x/本周x/下周x
 * 不在范围内，按完整日期显示
 * 需求文档:https://flowus.cn/dd8005a5-163e-45e9-86e0-6c6f4a1a63b5
 */
const getRelativeTime = (time: number) => {
  const sourceDay = dayjs(time);
  const today = dayjs();
  if (isAfter(sourceDay, 0)) return '今天';
  if (isAfter(sourceDay, 1)) return '明天';
  if (isAfter(sourceDay, 2)) return '后天';
  if (isBefore(sourceDay, 1)) return '昨天';
  if (isBefore(sourceDay, 2)) return '前天';
  //上周第一天（星期一）与最后一天（周日）
  const lastWeekFirstDay = today.subtract(7 + today.day() - 1, 'day');
  const lastWeekLastDay = lastWeekFirstDay.add(6, 'day');
  if (sourceDay.isBetween(lastWeekFirstDay, lastWeekLastDay, 'day', '[]')) {
    return ['上周', weekDesc.get(sourceDay.day())].join('');
  }
  //本周第一天与最后一天
  const thisWeekFirstDay = lastWeekLastDay.add(1, 'day');
  const thisWeekLastDay = thisWeekFirstDay.add(6, 'day');
  if (sourceDay.isBetween(thisWeekFirstDay, thisWeekLastDay, 'day', '[]')) {
    return ['本周', weekDesc.get(sourceDay.day())].join('');
  }
  //下周第一天与最后一天
  const nextWeekFirstDay = thisWeekLastDay.add(1, 'day');
  const nextWeekLastDay = nextWeekFirstDay.add(6, 'day');
  if (sourceDay.isBetween(nextWeekFirstDay, nextWeekLastDay, 'day', '[]')) {
    return ['下周', weekDesc.get(sourceDay.day())].join('');
  }
  return sourceDay.format(COMPLETE_DATE_FORMAT);
};

const isBefore = (source: Dayjs, day: number) => {
  const beforeDay = dayjs().subtract(day, 'day');
  return source.format(comparisonTemplate) === beforeDay.format(comparisonTemplate);
};
const isAfter = (source: Dayjs, day: number) => {
  const afterDay = dayjs().add(day, 'day');
  return source.format(comparisonTemplate) === afterDay.format(comparisonTemplate);
};

const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';
const DATE_FORMAT = 'YYYY/MM/DD';

export const readDateFromDateSegment = (dateSegment: SegmentDTO) => {
  let date: Date;
  if (dateSegment.type === TextType.DATETIME) {
    date = dayjs(`${dateSegment.startDate} ${dateSegment.startTime}`, DATE_TIME_FORMAT)
      .startOf('minute')
      .toDate();
  } else if (dateSegment.type === TextType.DATE) {
    date = dayjs(`${dateSegment.startDate}`, DATE_FORMAT).startOf('day').toDate();
  } else {
    date = dayjs().toDate();
  }
  return date;
};

export const isDateStringValid = (date: string, format: string) => {
  return dayjs(date, format, true).isValid();
};

/**通过时间戳获取特定格式的日期 */
export const getDateStrByMilliseconds = (
  datetime: number,
  dateFormat?: DateFormat,
  includeTime?: boolean,
  timeFormat?: TimeFormat
) => {
  dateFormat ??= DateFormat.YYYY_MM_DD;
  timeFormat ??= TimeFormat.H_MM;
  let dateStr: string;
  switch (dateFormat) {
    case DateFormat.LL:
      dateStr = dayjs(datetime).format(COMPLETE_DATE_FORMAT);
      break;
    case DateFormat.RELATIVE:
      dateStr = getRelativeTime(datetime);
      break;
    default:
      dateStr = dayjs(datetime).format(dateFormat.toLocaleUpperCase());
  }
  if (!includeTime) return dateStr;

  return `${dateStr} ${dayjs(datetime).format(timeFormat)}`;
};

/**通过segment获取特定格式的日期,在多维表上调用需要传递format参数 */
export const getDateStrBySegment = (
  dateSegment: SegmentDTO,
  dateFormat?: DateFormat,
  includeTime?: boolean,
  timeFormat?: TimeFormat
) => {
  dateFormat ??= dateSegment.dateFormat;
  includeTime ??= dateSegment.type === TextType.DATETIME;
  const date = readDateFromDateSegment(dateSegment);
  timeFormat ??= dateSegment.timeFormat;
  return getDateStrByMilliseconds(date.getTime(), dateFormat, includeTime, timeFormat);
};

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
/**计算提醒的时间 */
export const calculateRemindTimeByTimestamp = (
  datetime: number,
  reminder: SegmentDTO['reminder']
) => {
  if (!reminder) return;
  if (reminder.unit === 'day') {
    const hour = dayjs(reminder.time, 'HH:mm').get('hour');
    if (reminder.time) {
      return dayjs(datetime).set('hour', hour).unix() * 1000 - (reminder.value ?? 0) * DAY;
    }
    return dayjs(datetime).unix() * 1000 - (reminder.value ?? 0) * DAY;
  } else if (reminder.unit === 'hour') {
    return dayjs(datetime).unix() * 1000 - (reminder.value ?? 0) * HOUR;
  } else if (reminder.unit === 'minute') {
    return dayjs(datetime).unix() * 1000 - (reminder.value ?? 0) * MINUTE;
  }
};
/**计算提醒的时间 */
export const calculateRemindTime = (segment?: SegmentDTO) => {
  if (!segment) return;
  const dateValue = segmentsToDateValue(segment);
  if (!dateValue) return;
  return calculateRemindTimeByTimestamp(dateValue.timestamp, segment.reminder);
};

const segmentsToDateValue = (segment?: SegmentDTO) => {
  if (!segment) return;

  const date = dayjs(readDateFromDateSegment(segment));
  if (isNaN(date.toDate().getTime())) return;

  if (segment.type === TextType.DATE) {
    return {
      dateString: date.format(DATE_FORMAT),
      timestamp: date.valueOf(),
      textType: TextType.DATE,
    };
  }
  if (segment.type === TextType.DATETIME) {
    return {
      dateString: date.format(DATE_TIME_FORMAT),
      timestamp: date.valueOf(),
      textType: TextType.DATETIME,
    };
  }
};

export const saveCallTime = (key: string) => {
  localStorage.setItem(key, String(Date.now()));
};
export const removeCallTime = (key: string) => {
  localStorage.removeItem(key);
};
export const isAfterLastCallTime = (key: string, duration: number) => {
  const str = localStorage.getItem(key);
  if (!str) return true;
  const time = parseInt(str, 10);
  if (Date.now() - time > duration) return true;
  return false;
};
//如果时间是0点0分，则不展示，返回一个不包含时间的format
export const generateFormatByDate = (value: Date) => {
  //2022年1月1日 08:00
  let dateFormat = isBuildIn() ? 'MMMM DD, YYYY' : 'YYYY年M月D日';
  if (value.getSeconds() !== 0) {
    dateFormat += ' HH:mm:ss A';
  } else if (value.getMinutes() !== 0) {
    dateFormat += ' HH:mm A';
  } else if (value.getHours() !== 0) {
    dateFormat += ' HH:mm A';
  }
  if (!isBuildIn() && dateFormat.lastIndexOf('A') !== -1) {
    dateFormat = dateFormat.slice(0, dateFormat.length - 1);
  }
  return dateFormat;
};
