import type { VirtualElement } from '@popperjs/core';
import { AI_PAGE_ID } from './chat-ai/hooks/types';

/** 查元素是有符合条件，往上查找一直到某tagName,默认是html */
export const checkTargetByHtml = (
  target: HTMLElement,
  checkTarget: (t: HTMLElement) => boolean,
  topTagName = 'HTML'
) => {
  if (checkTarget(target)) {
    return true;
  }
  let t = target as HTMLElement;
  while (t.tagName !== topTagName) {
    const check = checkTarget(t);
    if (check || !t.parentNode) return true;
    t = t.parentNode as HTMLElement;
  }
  return false;
};

export const isHTML = (element?: Element | VirtualElement) => {
  return Boolean((element as HTMLElement)?.tagName);
};

/** 判断元素是否是input-like元素 */
export const isInAIChatPage = (element: HTMLElement) => {
  return element.classList.contains(AI_PAGE_ID) || element.id === AI_PAGE_ID;
};
